











































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TitleComponent from '@/components/TitleComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import ActionListItemComponent from '@/components/action-list/ActionListItemComponent.vue';

@Component({
  components: {
    TitleComponent,
    ActionListItemComponent,
  },
})
export default class ActionListWidget extends mixins(
  VueBaseWidget,
  VueRegisterStoreWidget,
) {
  protected baseStoreName = 'ActionListWidgetStore';

  @Prop({ required: false, default: '' })
  protected readonly title!: string;

  @Prop({ required: false, default: '' })
  protected readonly subtitle!: string;

  @Prop({ required: false, default: () => [] })
  private readonly actionItems!: Data[];

  created(): void {
    this.setDataConfig();
  }
}
